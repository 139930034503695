import React from 'react';
import "../Header/Header.css"
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <div>

  <header className="header_section">
  <div className='container-fluid header_top mt-3'>
  <div className='row header_top_container text-center'>
    <div className='col-md-3 contact_nav'>
    <p>
            <i className="fa fa-map-marker" aria-hidden="true" />
            <span>
            Gourmet Sanda Road 
            </span>
          </p>
    </div>
    <div className='col-md-3 contact_nav'>
    <p>
            <i className="fa fa-phone" aria-hidden="true" />
            <span>
              Call : +92 310-7770424
            </span>
          </p>
    </div>
    <div className='col-md-3 contact_nav'>
    <p>
            <i className="fa fa-envelope" aria-hidden="true" />
            <span>
            info@paper-supply.com
            </span>
          </p>
    </div>
    <div className='col-md-3  social_box'>
    <Link target='_blank' to="https://www.facebook.com/paperssupplier" style={{textDecoration:"none"}}>
          <i class="fa-brands fa-facebook-f"></i>
          </Link>
          <Link target='_blank' to="https://www.instagram.com/papersupplier2024/" style={{textDecoration:"none"}}>
          <i class="fa-brands fa-instagram"></i>
          </Link>
          <Link target='_blank' to="https://www.linkedin.com/company/paper-supplier/" style={{textDecoration:"none"}}>
          <i class="fa-brands fa-linkedin-in"></i>
          </Link>
          <Link target='_blank' to="https://www.youtube.com/@paper-supplier" style={{textDecoration:"none"}}>
          <i class="fa-brands fa-youtube"></i>
          </Link>
    </div>
  </div>
</div>
<hr style={{backgroundColor:"#FFC107"}}></hr>
    <div className="header_bottom">
      <div className="container-fluid">
        <nav className="navbar navbar-expand-lg custom_nav-container ">
            <div className='container-fluid'>
          <Link className="navbar-brand text-end  " to="/" ><img src='./assets/logo1.png' alt='logo' style={{width:"350px"}}/> </Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className> </span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav  ">
            <li className="nav-item" >
                <Link className="nav-link" to="/"> Home</Link>
              </li>
              
            <li className="nav-item" >
                <Link className="nav-link" to="/about"> About</Link>
              </li>
              
              <li className="nav-item">
                <Link className="nav-link" to="/product">Product</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/gallery">Gallery </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/news">News </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact">Contact</Link>
              </li>
            </ul>
          </div>
          </div>
        </nav>
      </div>
    </div>
  </header>
  {/* end header section */}
 
</div>


   
  )
}

export default Header
