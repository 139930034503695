import React, { useRef, useState } from 'react'
import Layout from '../Components/Layout'
import emailjs from '@emailjs/browser';
import {   ToastContainer ,  toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { Link } from 'react-router-dom';


const Contact = () => {
  const form = useRef();
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  // const [phone, setPhone] = useState("")
  const [message, setMessage] = useState("")

  const contactUser= (e) => {
        
    e.preventDefault();
    let userData = {name, email,  message}
    
    if (userData.name.length===0 || userData.email.length===0 || userData.message.length===0 )
     {
        // alert("error");
        toast.error("Please complete the form!", {
            position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
          });
       
    }
    else{
        emailjs
        .sendForm(
            'service_u15fhmk',
            'template_l5gvz7b',
            form.current, {
                publicKey: 'vblINXTeigQdG2T7d',
            })
            .then(
                () => {
                  console.log('SUCCESS!');
                },
                (error) => {
                  console.log('FAILED...', error.text);
                },
              );
              toast.success("Message send!", {
                position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
              });
              // Reset the form after successful submission
    //  form.reset();
    setName('') 
    setEmail('')
    setMessage('')
            }

  }

  return (
    <Layout>
       <div>
        <img src='./assets/contus.png' style={{width:"100%"}} alt='img'/>
        </div>
        <div className='container-fluid' style={{backgroundColor:"#FFC107"}}>
            <div className='row'>
                <div className='col-md-8 p-5' style={{fontSize:"20px"}}>
<p>
If you have a question about Paper Suppliers® or paper more generally — or if you have a comment or idea you'd like to share — we'd love to hear from you! Please contact us through the form below.
</p>
                </div>
            </div>
        </div>

        {/* contact section */}
        <div className='container my-5'>
    <div className='row'>
      <div className='col-md-12 text-center'>
<h1 style={{fontFamily:"Time New Roman", fontWeight:"900", marginTop:"20px", fontSize:"58px"}}>Get in Touch</h1>
<hr className='mx-auto mt-2 ' style={{width:"120px", backgroundColor:"#FFC107", height:"1px"}}/>
      </div>
    </div>

  </div>

   {/* Contact Form and info Section */}
   <div className="container my-5">
        <div className="row p-3" style={{ backgroundColor: '#FFC107' }}>
          {/* Contact Form Section */}

          <div className="col-lg-8 col-md-12 mb-4">
            
            <form className="contact-form text-dark mt-3" ref={form} onSubmit={contactUser}  >
              <div className="form-group">
                <label htmlFor="name">Name:</label>
                <input onChange={(e) => setName(e.target.value)}
                  type="text" className="form-control" id="name" name="name" value={name} placeholder="Enter your name" required />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email:</label>
                <input type="email" className="form-control" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" required />
              </div>
              {/* <div className="form-group">
                <label htmlFor="phone">Phone:</label>
                <input
                  type="phone" className="form-control" id="phone" name="phone" value={phone} onChange={(e) => setPhone(e.target.value)} placeholder="Enter your phone" required />
              </div> */}
              <div className="form-group">
                <label htmlFor="message">Message :</label>
                <textarea
                  className="form-control" id="message" name="message" value={message}  onChange={(e) => setMessage(e.target.value)} rows="5" placeholder="Enter your message" required ></textarea>
              </div>
              <button type="submit" value="Send" className="btn subbtn btn-block" style={{width:"150px"}} >Send Message</button>
              
            </form>
          </div>

          {/* info Section */}

          <div className="col-lg-4 col-md-4 mb-4  " >
       <div className='text-center mt-5 pt-4' >
        <h5>Call us anytime </h5>
        <p>+92 310-7770424</p>
       
        <br/>
        <h5>Come Visit Us</h5>
        <p>Gourmet Sanda Road</p>
        
        <br/>
        
        <h5>Send us a message</h5>
        <p> info@paper-supply.com</p>
        </div>   
       
          </div>
        </div>
      </div>
           {/* Map Section */}
           <div className="container-fluid">
        <div className="row">
          <div className="col-12">
          <iframe class="contact-map"  title="Your Unique and Descriptive Title" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d27195.86574235274!2d74.25462741083984!3d31.565794299999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39191cb7a52a80db%3A0x91e7bbf3afc4e65!2sGourmet%20Foods!5e0!3m2!1sen!2s!4v1722576106188!5m2!1sen!2s" width="100%" height="400"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>

      <div className='container my-3' style={{backgroundColor:"#FFC107"}}>
<div className='row p-5'>
    <div className='col-md-10'>
        <div className='heading'>
<h2 className='text-white'>LOOKING FOR QUALITY PAPER SUPPLIERS</h2>
<p className='fs-5'>We provide Different High Quality Paper Services. GET A QUOTE IN 20% OFF</p>
        </div>

    </div>
    <div className='col-md-2'>
        <div className='serbtn text-center p-2 '>
        <Link style={{textDecoration:"none" , color:"#fff"}} to="/contact">
            GET A QUOTE
          </Link>
        </div>

    </div>
</div>
</div>
      <ToastContainer />
    </Layout>
  )
}

export default Contact
